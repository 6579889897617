import { useQuery, useMutation } from '@tanstack/react-query'

import api from 'services/api'
import { getGAIds } from 'utils/helpers'

export const getConsultations = (id, page, data) =>
  api.post(`/patient/${id}/get_consultations/?page=${page}`, data)

export const getTrasactions = (id, page, dateFrom, dateTo, timezone) =>
  api.get(
    `/billing_account/${id}/transactions/?page=${page}&date_from=${dateFrom}&date_to=${dateTo}&timezone=${timezone}`
  )

export const getCartBalance = id => api.get(`/billing_account/${id}/balance/`)

export const getLastOrNextConsultations = (id, data) =>
  api.post(`/patient/${id}/get_last_or_next_consultations/`, data)

export const checkCoupon = (id, data) => api.post(`/checkout/${id}/coupon/`, data)

export const getConsultationDetail = (id, data) =>
  api.post(`/patient/${id}/consultation_detail/`, data)

export const getProfessionalBySlug = slug => api.post(`/professionals/${slug}/profile/`)

export const finishCheckout = (checkoutId, data) =>
  api.post(`/checkout/${checkoutId}/payment/`, { ...data, ...getGAIds() })

export const updateCheckoutDependent = (checkoutId, dependent = null, method = null) =>
  api.put(`/checkout/${checkoutId}/dependent/`, { dependent_id: dependent, method: method })

export const getCheckoutData = (id, data, isReadyOnly = 0) =>
  api.post(`/patient_purchase/${id}/checkout_detail/?is_read_only=${isReadyOnly}`, data)

export const getAllowedPaymentMethods = checkoutId =>
  api.get(`/checkout/${checkoutId}/allowed_payment_method/`)

export const getIsFirstAppointment = userId => api.get(`/patient/${userId}/is_first_appointment/`)

export const getAllowDiscountCoupon = value =>
  api.get(`/allow_discount_coupon/?cash_value=${value}`)

export const editConsultation = ({ checkoutId, consultationId }, data) =>
  api.patch(`/checkout/${checkoutId}/change_consultation/?consultation_id=${consultationId}`, data)

export const deleteConsultation = ({ checkoutId, consultationId, paymentMethod, timezone }) =>
  api.delete(
    `/checkout/${checkoutId}/change_consultation/?consultation_id=${consultationId}&method=${paymentMethod}&timezone=${timezone}/`
  )

export const useIsDiscountCouponAllowed = (amount, options = {}) =>
  useQuery(['is-discount-coupon-allowed', amount], () => getAllowDiscountCoupon(amount), {
    initialData: false,
    select: ({ data }) => data?.content?.allow_discount_coupon,
    ...options
  })

export const useAllowedPaymentMethods = (checkoutId, options = {}) =>
  useQuery(
    ['get-allowed-payment-methods', checkoutId],
    () => getAllowedPaymentMethods(checkoutId),
    {
      ...options,
      select: ({ data }) => data?.content?.payment_method
    }
  )

export const useIsFirstAppointment = (patientId, options = {}) =>
  useQuery(['get-is-first-appointment', patientId], () => getIsFirstAppointment(patientId), {
    ...options,
    initialData: false,
    select: ({ data }) => data?.content?.first_appointment || false
  })

export const useMutateCheckoutDependents = (checkoutId, options = {}) =>
  useMutation(
    ({ dependentId, method }) => updateCheckoutDependent(checkoutId, dependentId, method),
    {
      ...options
    }
  )

export const useFinishCheckout = (checkoutId, options = {}) =>
  useMutation(
    async data => {
      const response = await finishCheckout(checkoutId, data)
      return response?.data || {}
    },
    { ...options }
  )

export const useCheckout = ({ checkoutId, patientId, timezone, method = 'CC' }, options = {}) =>
  useQuery(
    ['get-checkout-data', checkoutId, patientId, method],
    () =>
      getCheckoutData(
        patientId,
        {
          checkout_id: checkoutId,
          timezone: timezone,
          method
        },
        1
      ),
    { ...options, enabled: !!patientId, select: ({ data }) => data?.content }
  )

export const useMutateCheckout = (patientId, options = {}) =>
  useMutation(
    async checkout => {
      const response = await getCheckoutData(patientId, checkout, 0)
      return response?.data?.content || {}
    },
    { ...options, enabled: !!patientId }
  )

export const useDeleteConsultation = (options = {}) =>
  useMutation(data => deleteConsultation(data), { ...options })
